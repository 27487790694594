import * as types from '../actions/actionTypes';

const initialState = {
	tokenLink: {
		inputValueType: null,
		inputName: null,
		inputValue: null,
	},
	bookingContext: {
		activeCareOrderDetails: {},
		availabilitySearchConfig: {},
		hasReservation: null,
		npi: null,
		patientReferenceId: null,
		referralSiteId: 0,
		siteId: null,
		specialtyId: null,
		specialtyName: null,
		unmanagedReferringServiceName: null, //
		zipCode: null,
	},
	bookingInitialized: false,
	correlationKey: null,
	decisionSupportSessionId: null,
	decisionSupportSubpoints: null,
	entryPointUrl: '/',
	sessionId: null,
	validEntryPoint: false,
	workboxId: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.STORE_TOKEN_LINK_PARAMETERS:
			return {
				...state,
				tokenLink: {
					inputValueType: action.inputValueType,
					inputName: action.inputName,
					inputValue: action.inputValue,
				}
			};
		case types.AUTHENTICATE_USER_SUCCESS:
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					availabilitySearchConfig: action.payload.data.config.availabilitySearch,
					referralSiteId: action.payload.data.referralSiteId,
				},
				entryPointUrl: action.payload.data.config.routePrefix ? `/${action.payload.data.config.routePrefix}/` : '/',
			};
		case types.SET_BOOKING_INITIALIZED:
			return {
				...state,
				bookingInitialized: true,
				correlationKey: action.correlationKey,
			};
		case types.WORKFLOW_CORRELATION_KEY_UPDATED:
			return {
				...state,
				correlationKey: action.correlationKey,
				decisionSupportSubpoints: null,
				workboxId: null,
			};
		case types.APPLIEDPATHWAYS_FETCH_WORKBOX_SUCCESS:
		case types.APPLIEDPATHWAYS_WORKFLOW_NEXT_SUCCESS:
		case types.APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_SUCCESS:
			return {
				...state,
				correlationKey: action.payload.data.state.correlationKey,
				decisionSupportSessionId: action.payload.data.state.decisionSupportSessionId,
				sessionId: action.payload.data.state.sessionId,
				workboxId: action.payload.data.state.workboxId,
			};
		case types.GUIDED_RESPONSE_START_FLOW_SUCCESS:
		case types.GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS:
			return {
				...state,
				decisionSupportSessionId: action.payload.data.flowSessionId,
				workboxId: null,
			};
		case types.GET_BOOKING_TRANSITION_DETAILS:
			return {
				...state,
				bookingInitialized: false,
			};
		case types.APPOINTMENT_BOOK_SUCCESS:
			return {
				...state,
				bookingInitialized: false,
				correlationKey: null,
				decisionSupportSessionId: null,
				decisionSupportSubpoints: null,
				workboxId: null,
			};
		case types.PROCESS_DECISION_SUPPORT_SUBPOINTS:
			return {
				...state,
				decisionSupportSubpoints: action.subpoints,
			};
		case types.SET_ENTRY_POINT:
			return {
				...state,
				validEntryPoint: true,
			};
		case types.CLEAR_ENTRY_POINT:
			return {
				...state,
				validEntryPoint: false,
			};
		case types.SET_CORRELATION_KEY_FOR_SURVEY:
			return {
				...state,
				correlationKey: action.correlationKey,
			};
		case types.SET_ENTRY_POINT_URL:
			let entryPointUrl = state.entryPointUrl;
			return {
				...state,
				entryPointUrl: (entryPointUrl += action.entryPointUrl),
			};
		case types.GET_BOOKING_TRANSITION_DETAILS_SUCCESS:
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					hasReservation: !!action.payload.data.reservation.reservationId,
					unmanagedReferringServiceName: action.payload.data.appointment.unmanagedReferringServiceName,
				},
				correlationKey: !action.payload.data.correlationKey ? null : action.payload.data.correlationKey,
			};
		case types.VERIFY_TOKEN_SUCCESS: {
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					availabilitySearchConfig: action.payload.data.config.availabilitySearch,
					referralSiteId: action.payload.data.referralSiteId,
				},
			};
		}
		case types.SET_BOOKING_CONTEXT:
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					npi: action.contextCriteria.npi,
					siteId: action.contextCriteria.siteId,
					specialtyId: action.contextCriteria.specialtyId,
					specialtyName: action.contextCriteria.specialtyName,
				},
			};
		case types.SET_INITIAL_AVAILABILITY_SEARCH_STATE_SUCCESS:
			let calendarId = null;
			let calendarIdList = action.payload.data.calendarIds;
			if (calendarIdList && calendarIdList.length === 1) {
				calendarId = calendarIdList[0];
			}
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					calendarId: calendarId,
				},
			};
		case types.GET_ACTIVE_CAREORDERVISIT_DETAILS_SUCCESS:
			return {
				...state,
				bookingContext: {
					...state.bookingContext,
					activeCareOrderDetails: action.payload.data,
					patientReferenceId: action.payload.data.patientReferenceId,
					zipCode: action.payload.data.patientZip,
				},
			};
		case types.GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_SUCCESS:
			return {
				...state,
				decisionSupportSessionData: action.payload.data,
				decisionSupportSessionId: action.payload.data.flowSessionId,
				decisionSupportSubpoints: mapFlowSessionResponseToSubpoints(action.payload.data)
			};
		default:
			return state;
	}
}

function mapFlowSessionResponseToSubpoints(flowSessionResponse) {
	let results = flowSessionResponse.results.fields;
	let subpoints = [];

	results.forEach(field => {
		let subpoint = { key: field.fieldName.toLowerCase(), value: field.currentValue };
		subpoints.push(subpoint);
	});

	return subpoints;
}
