import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearAllErrors } from '../../actions/errorActions';
import { verifyPatientForCareOrder, verifyPatientForSurveyOrder } from '../../actions/patientActions';
import { getFullSurveyOrder } from '../../actions/surveyOrderActions';
import { redirectToBookingProcess, redirectToSurveyProcess } from '../../actions/workflowActions';
import { INPUT_TYPES } from '../../constants/inputTypes';
import { buildFieldRefFromId } from '../../lib/fieldUtils';
import * as validationUtils from '../../lib/validationUtils';
import { deepCopy } from '../../lib/misc';
import { getFieldGroupsInfoFromFieldsConfig, getInitialFieldsState } from '../../lib/fieldConfigUtils';
import * as Button from '../../components/buttons';
import * as FieldControl from '../../components/input';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import ActiveErrors from '../../components/misc/activeErrors';
import { DisclaimerHtml } from '../../components/misc/miscComponents';
import { getFieldsConfig } from './patientAuthentication';

const FIRST_NAME_VARIABLE = '{firstName}';
const DEFAULT_VALIDATION_INSTRUCTIONS =
	"To protect private health information, we first need to confirm the patient's identity for {firstName}. Please enter the following information to continue.";

export class PatientAuthenticationView extends React.Component {
	constructor(props) {
		super(props);

		this.useSampleValues = false;
		this.fieldsConfig = getFieldsConfig(props);

		this.state = {
			errors: [],
			fields: getInitialFieldsState(this.fieldsConfig, null, null, this.useSampleValues),
			isFocusedLookup: {},
			isMobile: false,
			pageError: null,
			isVerifying: false,
			isLoadingSurvey: false,
		};
	}

	verifyPatientForCareOrder = (patientVerificationCriteria, careOrderVisitIdentifier) => {
		this.props.clearAllErrors();
		this.props
			.verifyPatientForCareOrder(patientVerificationCriteria, careOrderVisitIdentifier)
			.then((response) => {
				if (response.error) {
					this.setState({
						isVerifying: false,
					});
				} else {
					if (!this.props.patientAuthContext.isVerified) {
						this.setState({
							pageError: this.props.patientAuthContext.authMessage,
							isVerifying: false,
						});
					}
				}
			})
			.catch((err) => {
				this.setState({
					isVerifying: false,
				});
			});
	};

	loadFullSurveyOrderAndRedirect = (surveyOrderReferenceId, patientAuthContext) => {
		this.setState({
			isLoadingSurvey: true,
		});
		this.props
			.getFullSurveyOrder(surveyOrderReferenceId)
			.then((response) => {
				if (response.error) {
					this.setState({
						pageError: response.error,
						isVerifying: false,
					});
				} else {
					this.props.redirectToSurveyProcess(
						patientAuthContext,
						this.props.surveyOrderContext,
						this.props.config.instance.routePrefix,
					);
				}
			})
			.catch((err) => {
				this.setState({
					isVerifying: false,
				});
			});
	};

	verifyPatientForSurveyOrder = (patientVerificationCriteria, surveyIdentifier) => {
		this.props.clearAllErrors();
		this.props
			.verifyPatientForSurveyOrder(patientVerificationCriteria, surveyIdentifier)
			.then((response) => {
				if (response.error) {
					this.setState({
						isVerifying: false,
					});
				} else {
					if (!this.props.patientAuthContext.isVerified) {
						this.setState({
							pageError: this.props.patientAuthContext.authMessage,
							isVerifying: false,
						});
					}
				}
			})
			.catch((err) => {
				this.setState({
					isVerifying: false,
				});
			});
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.patientAuthContext.isVerified) {
			let { auth, patientAuthContext, bookingContext } = nextProps;

			let useDecisionSupport =
				nextProps.config.decisionSupport.useDecisionSupport &&
				nextProps.config.scheduling.useDecisionSupportWithCareOrders &&
				!nextProps.activeCareOrderDetails.decisionSupportSessionId

			if (this.props.activeCareOrderDetails.careOrderVisitIdentifier) {
				nextProps.redirectToBookingProcess(
					auth,
					patientAuthContext,
					useDecisionSupport,
					bookingContext,
					this.props.config.instance.routePrefix,
				);
			} else if (this.props.surveyOrderContext.referenceId && !this.state.isLoadingSurvey) {
				this.loadFullSurveyOrderAndRedirect(this.props.surveyOrderContext.referenceId, patientAuthContext);
			}
		}
	}

	handleInputChange = (e, searchFunc) => {
		e.preventDefault();

		let fieldRef = buildFieldRefFromId(e.target.name);
		let fieldConfig = this.fieldsConfig[fieldRef.fieldGroupName][fieldRef.fieldName];
		let newFieldGroup = deepCopy(this.state.fields[fieldRef.fieldGroupName]);

		// TODO: consider changing checkbox to raise "final value" instead of counting on a "toggle" behavior
		if (fieldConfig.inputType === INPUT_TYPES.CHECKBOX) {
			newFieldGroup[fieldRef.fieldName] = !newFieldGroup[fieldRef.fieldName];
		} else if (fieldConfig.inputType === INPUT_TYPES.DROPDOWN) {
			newFieldGroup[fieldRef.fieldName] = parseInt(e.target.value, 10);
		} else if (fieldConfig.inputType === INPUT_TYPES.BUTTON_OPTIONS) {
			newFieldGroup[fieldRef.fieldName] = this.filterArray(
				e.target.value,
				newFieldGroup[fieldRef.fieldName],
				fieldConfig.isMultiSelect,
				fieldConfig.isInteger,
			);
		} else {
			newFieldGroup[fieldRef.fieldName] = e.target.value;
		}

		this.setState(
			{
				fields: {
					...this.state.fields,
					[fieldRef.fieldGroupName]: newFieldGroup,
				},
			},
			() => {
				const errors = validationUtils.validateField(
					this.state.errors,
					this.state.fields,
					this.props,
					this.fieldsConfig,
					fieldRef,
				);
				this.setState({ errors });
			},
		);
	};

	handleInputFocus = (e) => {
		let isFocusedLookup = {};
		isFocusedLookup[e.target.name] = true;
		this.setState({ isFocusedLookup });
	};

	handleInputBlur = (e) => {
		let isFocusedLookup = { ...this.state.isFocusedLookup };
		delete isFocusedLookup[e.target.name];
		this.setState({ isFocusedLookup });
	};

	onVerifyPatientClick = (e) => {
		e.preventDefault();
		this.props.clearAllErrors();
		const errors = validationUtils.validateForm(this.state.errors, this.state.fields, this.props, this.fieldsConfig);

		if (!errors.length) {
			let patientVerificationCriteria = {
				systemId: this.props.systemId,
				productInstanceName: this.props.auth.name,
				patientId: this.props.patientAuthContext.patientIdentification.patientIdentifier,
				patientIdType: this.props.patientAuthContext.patientIdentification.patientIdentifierType,
				birthYear: this.state.fields.patientAuth.birthYear,
				dateOfBirth: this.state.fields.patientAuth.dateOfBirth,
				zipCode: this.state.fields.patientAuth.zipCode,
				correlationKey: this.props.session.correlationKey,
			};

			this.setState({ isVerifying: true });
			if (this.props.activeCareOrderDetails.careOrderVisitIdentifier) {
				this.verifyPatientForCareOrder(
					patientVerificationCriteria,
					this.props.activeCareOrderDetails.careOrderVisitIdentifier,
				);
			} else if (this.props.surveyOrderContext.referenceId) {
				this.verifyPatientForSurveyOrder(patientVerificationCriteria, this.props.surveyOrderContext.referenceId);
			}
		} else {
			this.setState({ errors });
		}
	};

	render() {
		const fieldGroupsInfo = getFieldGroupsInfoFromFieldsConfig(
			this.state.errors,
			this.state.fields,
			this.fieldsConfig,
			this.props,
			this.state.isFocusedLookup,
		);
		const validationInstructions = this.props.patientAuthContext.patientAuthConfig.validationInstructions;
		const patientFirstName = this.props.patient.patientFirstName;
		const effectiveValidationInstructions = validationInstructions
			? validationInstructions.replace(FIRST_NAME_VARIABLE, patientFirstName)
			: DEFAULT_VALIDATION_INSTRUCTIONS.replace(FIRST_NAME_VARIABLE, patientFirstName);

		return (
			<div className="view-content patient-authentication">
				<ActiveErrors />
				{this.state.isVerifying && <LoadingIndicatorModal />}
				<main className={'main-content'}>
					{this.state.pageError && !this.props.patientAuthContext.isLockedOut && (
						<div className="page-error default">
							<p>* {this.state.pageError}</p>
						</div>
					)}
					{this.props.patientAuthContext.isLockedOut && (
						<DisclaimerHtml
							className={'locked-out'}
							isVisible={true}
							text={this.props.patientAuthContext.authMessage}
						/>
					)}
					{!this.props.patientAuthContext.isLockedOut && (
						<div className="section-body">
							<form onSubmit={this.onVerifyPatientClick} className="patient-authentication-form">
								<div className="section-row">
									<DisclaimerHtml
										className="form-instructions"
										isVisible={true}
										text={effectiveValidationInstructions}
									/>
								</div>
								<div className="section-row">
									<FieldControl.YearInput
										{...fieldGroupsInfo.patientAuth.birthYear}
										onChange={this.handleInputChange}
										onFocus={this.handleInputFocus}
										onBlur={this.handleInputBlur}
									/>
								</div>
								<div className="section-row">
									<FieldControl.DateInput
										{...fieldGroupsInfo.patientAuth.dateOfBirth}
										onChange={this.handleInputChange}
										onFocus={this.handleInputFocus}
										onBlur={this.handleInputBlur}
									/>
								</div>
								<div className="section-row">
									<FieldControl.ZipInput
										{...fieldGroupsInfo.patientAuth.zipCode}
										onChange={this.handleInputChange}
										onFocus={this.handleInputFocus}
										onBlur={this.handleInputBlur}
									/>
								</div>
								<div className="submit-buttons-container">
									<Button.ButtonPrimary className="verify-patient" type="submit" disabled={this.state.isVerifying}>
										Verify
									</Button.ButtonPrimary>
								</div>
							</form>
						</div>
					)}
				</main>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrderDetails: state.careOrder.activeCareOrderDetails,
		auth: state.auth,
		bookingContext: state.session.bookingContext,
		config: state.config,
		isLoading: state.ajaxCallsInProgress > 0,
		patient: state.patient.patient,
		patientAuth: state.config.patientAuth,
		patientAuthContext: state.patient.patientAuthContext,
		session: state.session,
		surveyOrderContext: state.surveyOrder,
		systemId: state.auth.referralSystemId,
		token: state.auth.token,
		useDecisionSupport: state.config.decisionSupport,
	};
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			clearAllErrors,
			verifyPatientForCareOrder,
			verifyPatientForSurveyOrder,
			getFullSurveyOrder,
			redirectToBookingProcess,
			redirectToSurveyProcess,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAuthenticationView);
