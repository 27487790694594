import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware';

// token link
export const STORE_TOKEN_LINK_PARAMETERS = 'STORE_TOKEN_LINK_PARAMETERS';

// applied pathways
export const APPLIEDPATHWAYS_FETCH_WORKBOX = 'APPLIEDPATHWAYS_FETCH_WORKBOX';
export const APPLIEDPATHWAYS_FETCH_WORKBOX_SUCCESS = `${APPLIEDPATHWAYS_FETCH_WORKBOX}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_FETCH_WORKBOX_ERROR = `${APPLIEDPATHWAYS_FETCH_WORKBOX}${ERROR_SUFFIX}`;

export const APPLIEDPATHWAYS_WORKFLOW_NEXT = 'APPLIEDPATHWAYS_WORKFLOW_NEXT';
export const APPLIEDPATHWAYS_WORKFLOW_NEXT_SUCCESS = `${APPLIEDPATHWAYS_WORKFLOW_NEXT}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_WORKFLOW_NEXT_ERROR = `${APPLIEDPATHWAYS_WORKFLOW_NEXT}${ERROR_SUFFIX}`;

export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS = 'APPLIEDPATHWAYS_WORKFLOW_PREVIOUS';
export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_SUCCESS = `${APPLIEDPATHWAYS_WORKFLOW_PREVIOUS}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_ERROR = `${APPLIEDPATHWAYS_WORKFLOW_PREVIOUS}${ERROR_SUFFIX}`;

// appointment
export const APPOINTMENT_DETAILS = 'APPOINTMENT_DETAILS';
export const APPOINTMENT_DETAILS_SUCCESS = `${APPOINTMENT_DETAILS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_DETAILS_ERROR = `${APPOINTMENT_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVE_DETAILS = `APPOINTMENT_RESERVE_DETAILS`;
export const APPOINTMENT_RESERVE_DETAILS_SUCCESS = `${APPOINTMENT_RESERVE_DETAILS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVE_DETAILS_ERROR = `${APPOINTMENT_RESERVE_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_BOOK = 'APPOINTMENT_BOOK';
export const APPOINTMENT_BOOK_SUCCESS = `${APPOINTMENT_BOOK}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_BOOK_ERROR = `${APPOINTMENT_BOOK}${ERROR_SUFFIX}`;

export const APPOINTMENT_BOOK_NEW_PATIENT = 'APPOINTMENT_BOOK_NEW_PATIENT';
export const APPOINTMENT_BOOK_NEW_PATIENT_SUCCESS = `${APPOINTMENT_BOOK_NEW_PATIENT}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_BOOK_NEW_PATIENT_ERROR = `${APPOINTMENT_BOOK_NEW_PATIENT}${ERROR_SUFFIX}`;

export const APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL';
export const APPOINTMENT_CANCEL_SUCCESS = `${APPOINTMENT_CANCEL}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_CANCEL_ERROR = `${APPOINTMENT_CANCEL}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVE = 'APPOINTMENT_RESERVE';
export const APPOINTMENT_RESERVE_SUCCESS = `${APPOINTMENT_RESERVE}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVE_ERROR = `${APPOINTMENT_RESERVE}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVATION_CANCEL = `APPOINTMENT_RESERVATION_CANCEL`;
export const APPOINTMENT_RESERVATION_CANCEL_SUCCESS = `${APPOINTMENT_RESERVATION_CANCEL}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVATION_CANCEL_ERROR = `${APPOINTMENT_RESERVATION_CANCEL}${ERROR_SUFFIX}`;

export const APPOINTMENT_PATIENT_ICS = 'APPOINTMENT_PATIENT_ICS';
export const APPOINTMENT_PATIENT_ICS_SUCCESS = `${APPOINTMENT_PATIENT_ICS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_PATIENT_ICS_ERROR = `${APPOINTMENT_PATIENT_ICS}${ERROR_SUFFIX}`;

export const CUSTOM_FIELDS = 'CUSTOM_FIELDS';
export const CUSTOM_FIELDS_SUCCESS = `${CUSTOM_FIELDS}${SUCCESS_SUFFIX}`;
export const CUSTOM_FIELDS_ERROR = `${CUSTOM_FIELDS}${ERROR_SUFFIX}`;

export const GET_REFERRING_PROVIDERS = 'GET_REFERRING_PROVIDERS';
export const GET_REFERRING_PROVIDERS_SUCCESS = `${GET_REFERRING_PROVIDERS}${SUCCESS_SUFFIX}`;
export const GET_REFERRING_PROVIDERS_ERROR = `${GET_REFERRING_PROVIDERS}${ERROR_SUFFIX}`;

export const GET_ORG_REFERRING_PROVIDERS = 'GET_ORG_REFERRING_PROVIDERS';
export const GET_ORG_REFERRING_PROVIDERS_SUCCESS = `${GET_ORG_REFERRING_PROVIDERS}${SUCCESS_SUFFIX}`;
export const GET_ORG_REFERRING_PROVIDERS_ERROR = `${GET_ORG_REFERRING_PROVIDERS}${ERROR_SUFFIX}`;

export const GET_BOOKING_TRANSITION_DETAILS = 'GET_BOOKING_TRANSITION_DETAILS';
export const GET_BOOKING_TRANSITION_DETAILS_SUCCESS = `${GET_BOOKING_TRANSITION_DETAILS}${SUCCESS_SUFFIX}`;
export const GET_BOOKING_TRANSITION_DETAILS_ERROR = `${GET_BOOKING_TRANSITION_DETAILS}${ERROR_SUFFIX}`;

export const SET_INITIAL_APPOINTMENT_DETAILS = `SET_INITIAL_APPOINTMENT_DETAILS`;

export const APPOINTMENT_SEARCH_ADDRESS = 'APPOINTMENT_SEARCH_ADDRESS'
export const APPOINTMENT_SEARCH_ADDRESS_SUCCESS = `${APPOINTMENT_SEARCH_ADDRESS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_SEARCH_ADDRESS_ERROR = `${APPOINTMENT_SEARCH_ADDRESS}${ERROR_SUFFIX}`;

// auth
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = `${AUTHENTICATE_USER}${SUCCESS_SUFFIX}`;
export const AUTHENTICATE_USER_ERROR = `${AUTHENTICATE_USER}${ERROR_SUFFIX}`;

export const LOGOUT_USER = 'LOGOUT_USER';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN_SUCCESS = `${VERIFY_TOKEN}${SUCCESS_SUFFIX}`;
export const VERIFY_TOKEN_ERROR = `${VERIFY_TOKEN}${ERROR_SUFFIX}`;

// availability

export const SEARCH_CALENDARS = 'SEARCH_CALENDARS';
export const SEARCH_CALENDARS_SUCCESS = `${SEARCH_CALENDARS}${SUCCESS_SUFFIX}`;
export const SEARCH_CALENDARS_ERROR = `${SEARCH_CALENDARS}${ERROR_SUFFIX}`;

export const SCAN_FIRST_AVAILABILITY = 'SCAN_FIRST_AVAILABILITY';
export const SCAN_FIRST_AVAILABILITY_SUCCESS = `${SCAN_FIRST_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SCAN_FIRST_AVAILABILITY_ERROR = `${SCAN_FIRST_AVAILABILITY}${ERROR_SUFFIX}`;

export const SCAN_NEXT_AVAILABILITY = 'SCAN_NEXT_AVAILABILITY';
export const SCAN_NEXT_AVAILABILITY_SUCCESS = `${SCAN_NEXT_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SCAN_NEXT_AVAILABILITY_ERROR = `${SCAN_NEXT_AVAILABILITY}${ERROR_SUFFIX}`;

export const SEARCH_AVAILABILITY = 'SEARCH_AVAILABILITY';
export const SEARCH_AVAILABILITY_SUCCESS = `${SEARCH_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SEARCH_AVAILABILITY_ERROR = `${SEARCH_AVAILABILITY}${ERROR_SUFFIX}`;

export const SET_SCAN_NEXT_AVAILABILITY_RESULTS = "SET_SCAN_NEXT_AVAILABILITY_RESULTS";

export const SET_INITIAL_AVAILABILITY_SEARCH_STATE = `SET_INITIAL_AVAILABILITY_SEARCH_STATE`;
export const SET_INITIAL_AVAILABILITY_SEARCH_STATE_SUCCESS = `${SET_INITIAL_AVAILABILITY_SEARCH_STATE}${SUCCESS_SUFFIX}`;
export const SET_INITIAL_AVAILABILITY_SEARCH_STATE_ERROR = `${SET_INITIAL_AVAILABILITY_SEARCH_STATE}${ERROR_SUFFIX}`;

export const RESET_AVAILABILITY_STATE = 'RESET_AVAILABILITY_STATE';
export const SET_ACTIVE_PATIENT = 'SET_ACTIVE_PATIENT';

export const SORT_CALENDARS_BY_FIRST_AVAILABILITY = 'SORT_CALENDARS_BY_FIRST_AVAILABILITY';

// care order
export const GET_ACTIVE_CAREORDERVISIT_DETAILS = 'GET_ACTIVE_CAREORDERVISIT_DETAILS';
export const GET_ACTIVE_CAREORDERVISIT_DETAILS_SUCCESS = `${GET_ACTIVE_CAREORDERVISIT_DETAILS}${SUCCESS_SUFFIX}`;
export const GET_ACTIVE_CAREORDERVISIT_DETAILS_ERROR = `${GET_ACTIVE_CAREORDERVISIT_DETAILS}${ERROR_SUFFIX}`;

// custom fields
export const SET_INITIAL_CUSTOM_FIELD_ANSWERS = `SET_INITIAL_CUSTOM_FIELD_ANSWERS`;

// decision support
export const PROCESS_DECISION_SUPPORT_SUBPOINTS = 'PROCESS_DECISION_SUPPORT_SUBPOINTS';
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD = 'GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD'
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_SUCCESS = `${GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD}${SUCCESS_SUFFIX}`
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_ERROR = `${GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD}${ERROR_SUFFIX}`

// error
export const PUBLISH_ERROR = 'PUBLISH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';

// guided response
export const GUIDED_RESPONSE_START_FLOW = 'GUIDED_RESPONSE_START_FLOW';
export const GUIDED_RESPONSE_START_FLOW_SUCCESS = `${GUIDED_RESPONSE_START_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_START_FLOW_ERROR = `${GUIDED_RESPONSE_START_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_CONTINUE_FLOW = 'GUIDED_RESPONSE_CONTINUE_FLOW';
export const GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS = `${GUIDED_RESPONSE_CONTINUE_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_CONTINUE_FLOW_ERROR = `${GUIDED_RESPONSE_CONTINUE_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_PREVIOUS = 'GUIDED_RESPONSE_PREVIOUS';
export const GUIDED_RESPONSE_PREVIOUS_SUCCESS = `${GUIDED_RESPONSE_PREVIOUS}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_PREVIOUS_ERROR = `${GUIDED_RESPONSE_PREVIOUS}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_DEBUG_START_FLOW = 'GUIDED_RESPONSE_DEBUG_START_FLOW';
export const GUIDED_RESPONSE_DEBUG_START_FLOW_SUCCESS = `${GUIDED_RESPONSE_DEBUG_START_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_DEBUG_START_FLOW_ERROR = `${GUIDED_RESPONSE_DEBUG_START_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW = 'GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW';
export const GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW_SUCCESS = `${GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW_ERROR = `${GUIDED_RESPONSE_DEBUG_CONTINUE_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_GET_FLOW_SESSION = 'GUIDED_RESPONSE_GET_FLOW_SESSION';
export const GUIDED_RESPONSE_GET_FLOW_SESSION_SUCCESS = `${GUIDED_RESPONSE_GET_FLOW_SESSION}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_GET_FLOW_SESSION_ERROR = `${GUIDED_RESPONSE_GET_FLOW_SESSION}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_DEBUG_PREVIOUS = 'GUIDED_RESPONSE_DEBUG_PREVIOUS';
export const GUIDED_RESPONSE_DEBUG_PREVIOUS_SUCCESS = `${GUIDED_RESPONSE_DEBUG_PREVIOUS}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_DEBUG_PREVIOUS_ERROR = `${GUIDED_RESPONSE_DEBUG_PREVIOUS}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS = 'GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS';

export const GUIDED_RESPONSE_DEBUG_PATH = 'GUIDED_RESPONSE_DEBUG_PATH';

export const SET_GUIDED_RESPONSE_DEBUG_CONTEXT = 'SET_GUIDED_RESPONSE_DEBUG_CONTEXT';

export const GET_GUIDED_RESPONSE_DSV_RESULTS = 'GET_GUIDED_RESPONSE_DSV_RESULTS';

// modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// patient
export const SET_PATIENT_DEMOGRAPHICS = `SET_PATIENT_DEMOGRAPHICS`;

export const VERIFY_PATIENT_FOR_CAREORDER = 'VERIFY_PATIENT_FOR_CAREORDER';
export const VERIFY_PATIENT_FOR_CAREORDER_SUCCESS = `${VERIFY_PATIENT_FOR_CAREORDER}${SUCCESS_SUFFIX}`;
export const VERIFY_PATIENT_FOR_CAREORDER_ERROR = `${VERIFY_PATIENT_FOR_CAREORDER}${ERROR_SUFFIX}`;

export const VERIFY_PATIENT_FOR_SURVEY_ORDER = 'VERIFY_PATIENT_FOR_SURVEY_ORDER';
export const VERIFY_PATIENT_FOR_SURVEY_ORDER_SUCCESS = `${VERIFY_PATIENT_FOR_SURVEY_ORDER}${SUCCESS_SUFFIX}`;
export const VERIFY_PATIENT_FOR_SURVEY_ORDER_ERROR = `${VERIFY_PATIENT_FOR_SURVEY_ORDER}${ERROR_SUFFIX}`;

// session
export const CLEAR_ENTRY_POINT = 'CLEAR_ENTRY_POINT';
export const WORKFLOW_CORRELATION_KEY_UPDATED = 'WORKFLOW_CORRELATION_KEY_UPDATED';

// survey
export const GET_SURVEY_ORDER = 'GET_SURVEY_ORDER';
export const GET_SURVEY_ORDER_SUCCESS = `${GET_SURVEY_ORDER}${SUCCESS_SUFFIX}`;
export const GET_SURVEY_ORDER_ERROR = `${GET_SURVEY_ORDER}${ERROR_SUFFIX}`;

export const GET_FULL_SURVEY_ORDER_DETAILS = 'GET_FULL_SURVEY_ORDER_DETAILS';
export const GET_FULL_SURVEY_ORDER_DETAILS_SUCCESS = `${GET_FULL_SURVEY_ORDER_DETAILS}${SUCCESS_SUFFIX}`;
export const GET_FULL_SURVEY_ORDER_DETAILS_ERROR = `${GET_FULL_SURVEY_ORDER_DETAILS}${ERROR_SUFFIX}`;

export const START_SURVEY_ORDER = 'START_SURVEY_ORDER';
export const START_SURVEY_ORDER_SUCCESS = `${START_SURVEY_ORDER}${SUCCESS_SUFFIX}`;
export const START_SURVEY_ORDER_ERROR = `${START_SURVEY_ORDER}${ERROR_SUFFIX}`;

export const COMPLETE_SURVEY_ORDER = 'COMPLETE_SURVEY_ORDER';
export const COMPLETE_SURVEY_ORDER_SUCCESS = `${COMPLETE_SURVEY_ORDER}${SUCCESS_SUFFIX}`;
export const COMPLETE_SURVEY_ORDER_ERROR = `${COMPLETE_SURVEY_ORDER}${ERROR_SUFFIX}`;

// workflow
export const SET_CORRELATION_KEY_FOR_SURVEY = 'SET_CORRELATION_KEY_FOR_SURVEY';
export const SET_BOOKING_CONTEXT = 'SET_BOOKING_CONTEXT';
export const SET_ENTRY_POINT = 'SET_ENTRY_POINT';
export const SET_ENTRY_POINT_URL = 'SET_ENTRY_POINT_URL';
export const CLEAR_DECISION_SUPPORT = 'CLEAR_DECISION_SUPPORT';
export const SET_BOOKING_INITIALIZED = `SET_BOOKING_INITIALIZED`;

// availability survey
export const VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY = `VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY`;
export const VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY_SUCCESS = `${VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY}${SUCCESS_SUFFIX}`;
export const VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY_ERROR = `${VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY}${ERROR_SUFFIX}`;

export const CLOSE_COMPLETED_AVAILABILITY_SURVEY = 'CLOSE_COMPLETED_AVAILABILITY_SURVEY';
export const CLOSE_COMPLETED_AVAILABILITY_SURVEY_SUCCESS = `${CLOSE_COMPLETED_AVAILABILITY_SURVEY}${SUCCESS_SUFFIX}`;
export const CLOSE_COMPLETED_AVAILABILITY_SURVEY_ERROR = `${CLOSE_COMPLETED_AVAILABILITY_SURVEY}${ERROR_SUFFIX}`;

export const GET_AVAILABILITY_SURVEY_STATUS = 'GET_AVAILABILITY_SURVEY_STATUS';
export const GET_AVAILABILITY_SURVEY_STATUS_SUCCESS = `${GET_AVAILABILITY_SURVEY_STATUS}${SUCCESS_SUFFIX}`;
export const GET_AVAILABILITY_SURVEY_STATUS_ERROR = `${GET_AVAILABILITY_SURVEY_STATUS}${ERROR_SUFFIX}`;

export const LOAD_AVAILABILITY_SURVEY = 'LOAD_AVAILABILITY_SURVEY';
export const LOAD_AVAILABILITY_SURVEY_SUCCESS = `${LOAD_AVAILABILITY_SURVEY}${SUCCESS_SUFFIX}`;
export const LOAD_AVAILABILITY_SURVEY_ERROR = `${LOAD_AVAILABILITY_SURVEY}${ERROR_SUFFIX}`;

export const LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS = 'LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS';
export const LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_SUCCESS = `${LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${SUCCESS_SUFFIX}`;
export const LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_ERROR = `${LOAD_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${ERROR_SUFFIX}`;

export const SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS = 'SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS';
export const SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_SUCCESS = `${SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${SUCCESS_SUFFIX}`;
export const SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_ERROR = `${SAVE_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${ERROR_SUFFIX}`;

export const SAVE_AVAILABILITY_SURVEY_DATES = 'SAVE_AVAILABILITY_SURVEY_DATES';
export const SAVE_AVAILABILITY_SURVEY_DATES_SUCCESS = `${SAVE_AVAILABILITY_SURVEY_DATES}${SUCCESS_SUFFIX}`;
export const SAVE_AVAILABILITY_SURVEY_DATES_ERROR = `${SAVE_AVAILABILITY_SURVEY_DATES}${ERROR_SUFFIX}`;

export const SAVE_AVAILABILITY_SURVEY_EMAIL = 'SAVE_AVAILABILITY_SURVEY_EMAIL';
export const SAVE_AVAILABILITY_SURVEY_EMAIL_SUCCESS = `${SAVE_AVAILABILITY_SURVEY_EMAIL}${SUCCESS_SUFFIX}`;
export const SAVE_AVAILABILITY_SURVEY_EMAIL_ERROR = `${SAVE_AVAILABILITY_SURVEY_EMAIL}${ERROR_SUFFIX}`;

export const SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS = 'SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS';
export const SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_SUCCESS = `${SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${SUCCESS_SUFFIX}`;
export const SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS_ERROR = `${SET_AVAILABILITY_SURVEY_ADDITIONAL_ANSWERS}${ERROR_SUFFIX}`;

export const SET_AVAILABILITY_SURVEY_RESPONSE = 'SET_AVAILABILITY_SURVEY_RESPONSE';
export const SET_AVAILABILITY_SURVEY_RESPONSE_SUCCESS = `${SET_AVAILABILITY_SURVEY_RESPONSE}${SUCCESS_SUFFIX}`;
export const SET_AVAILABILITY_SURVEY_RESPONSE_ERROR = `${SET_AVAILABILITY_SURVEY_RESPONSE}${ERROR_SUFFIX}`;

export const VALIDATE_ZIP_CODE = 'VALIDATE_ZIP_CODE';
export const VALIDATE_ZIP_CODE_SUCCESS = `${VALIDATE_ZIP_CODE}${SUCCESS_SUFFIX}`;
export const VALIDATE_ZIP_CODE_ERROR = `${VALIDATE_ZIP_CODE}${ERROR_SUFFIX}`;

// Ops Product Alert Banner
export const GET_PRODUCT_ALERT_CONFIG = 'GET_PRODUCT_ALERT_CONFIG';
export const GET_PRODUCT_ALERT_CONFIG_SUCCESS = `${GET_PRODUCT_ALERT_CONFIG}${SUCCESS_SUFFIX}`;
export const GET_PRODUCT_ALERT_CONFIG_ERROR = `${GET_PRODUCT_ALERT_CONFIG}${ERROR_SUFFIX}`;
