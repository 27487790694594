/**
 * @format
 */

import moment from 'moment';
import * as actionTypes from './actionTypes';
import { AVAILABILITY_SEARCH_CRITERIA } from '../constants/availabilitySearchCriteria';
import * as IdentityTypes from '../constants/mhdDefaultIdentityTypes';

export function setInitialAvailabilitySearchState(
	careOrderSearchCriteria,
	decisionSupportSearchCriteria,
	availabilitySearchConfig,
	patientDetails,
) {
	return function (dispatch) {
		dispatch(
			setInitialAvailabilitySearchStateAction(
				careOrderSearchCriteria,
				decisionSupportSearchCriteria,
				availabilitySearchConfig,
				patientDetails,
			),
		);
		return Promise.resolve();
	};
}

//TODO refactor customFieldAnswers to use decision support custom fields and care order custom fields
function setInitialAvailabilitySearchStateAction(
	careOrderSearchCriteria,
	decisionSupportSearchCriteria,
	availabilitySearchConfig,
	patientDetails,
) {
	const getInputList = (lookupKey, defaultValue) => {
		return decisionSupportSearchCriteria[lookupKey] || careOrderSearchCriteria[lookupKey] || defaultValue;
	};

	const inputLists = {
		calendarIds: getInputList(AVAILABILITY_SEARCH_CRITERIA.additionalCalendarList, []),
		siteIds: getInputList(AVAILABILITY_SEARCH_CRITERIA.siteIdList, []),
		serviceIds: getInputList(AVAILABILITY_SEARCH_CRITERIA.serviceIdList, []),
		serviceNpis: getInputList(AVAILABILITY_SEARCH_CRITERIA.serviceNpiList, null),
		selectedSiteIds: getInputList(AVAILABILITY_SEARCH_CRITERIA.selectedSiteIdList, null),
		selectedServiceNpis: getInputList(AVAILABILITY_SEARCH_CRITERIA.selectedProviderNpiList, null),
		selectedCalendarIds: getInputList(AVAILABILITY_SEARCH_CRITERIA.selectedProviderCalendarList, null),
	};

	class CriteriaInputs {
		constructor(des, list, criteriaType, lookupKey, isSelected) {
			this.des = des;
			this.list = list;
			this.criteriaType = criteriaType;
			this.lookupKey = lookupKey;
			this.isSelected = isSelected;
		}
	}

	const lookupCriteria = {
		calendarLookupCriteria: [],
		siteLookupCriteria: [],
		serviceLookupCriteria: [],
	};

	// check for differing service types
	if (inputLists.serviceNpis?.length > 0 && inputLists.serviceIds?.length > 0) {
		console.log('Both service npis and service ids where provided from decision support, this is not supported');
	}

	let serviceCriteria = inputLists.serviceNpis
		? new CriteriaInputs('serviceLookupCriteria', inputLists.serviceNpis, 'serviceIdentifier', IdentityTypes.Npi, false)
		: new CriteriaInputs(
			'serviceLookupCriteria',
			inputLists.serviceIds,
			'serviceIdentifier',
			IdentityTypes.ServiceId,
			false,
		);

	const criteriaLists = [
		//calendars
		new CriteriaInputs(
			'calendarLookupCriteria',
			inputLists.selectedCalendarIds,
			'calendarIdentifier',
			'calendar id',
			true,
		),
		new CriteriaInputs(
			'calendarLookupCriteria',
			inputLists.calendarIds,
			'calendarIdentifier',
			IdentityTypes.CalendarId,
			false,
		),

		//site
		new CriteriaInputs('siteLookupCriteria', inputLists.selectedSiteIds, 'siteIdentifier', IdentityTypes.SiteId, true),
		new CriteriaInputs('siteLookupCriteria', inputLists.siteIds, 'siteIdentifier', IdentityTypes.SiteId, false),

		//service
		new CriteriaInputs(
			'serviceLookupCriteria',
			inputLists.selectedServiceNpis,
			'serviceIdentifier',
			IdentityTypes.Npi,
			true,
		),
		serviceCriteria,
	];

	criteriaLists.forEach((x) => {
		let mappedCriteria = mapLookupCriteria(x.list, x.criteriaType, x.lookupKey, x.isSelected);
		if (mappedCriteria) {
			lookupCriteria[x.des] = lookupCriteria[x.des] ? lookupCriteria[x.des].concat(mappedCriteria) : mappedCriteria;
		}
	});

	let combinedCalendarLookupCriteria = [
		...lookupCriteria.calendarLookupCriteria,
		...combineSiteAndServiceIdentityCriteria(
			lookupCriteria.siteLookupCriteria.filter((x) => x.isPreferredCalendar === false),
			lookupCriteria.serviceLookupCriteria.filter((x) => x.isPreferredCalendar === false),
			false,
		),

		...combineSiteAndServiceIdentityCriteria(
			lookupCriteria.siteLookupCriteria.filter((x) => x.isPreferredCalendar === true),
			lookupCriteria.serviceLookupCriteria.filter((x) => x.isPreferredCalendar === true),
			true,
		),
	];

	let minStartDateString =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.startDate] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.startDate] ||
		null;

	let minStartDate =
		minStartDateString && moment(minStartDateString) >= moment()
			? moment(minStartDateString).format()
			: moment().format(); // TODO: verify can use default format

	let maxStartDateString =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.endDate] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.endDate] ||
		null;

	let maxStartDate = maxStartDateString ? moment(maxStartDateString).format() : null; // TODO: verify can use default format

	let minStartTime =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.minStartTime] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.minStartTime] ||
		null;

	let maxStartTime =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.maxStartTime] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.maxStartTime] ||
		null;

	let appointmentTypeIds =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.appointmentTypeIdList] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.appointmentTypeIdList] ||
		[];

	let serviceProviderTypeId =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.serviceTypeId] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.serviceTypeId] ||
		null;

	let specialtyId =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.specialtyId] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.specialtyId] ||
		null;

	let sortOrder = availabilitySearchConfig.availabilitySortOrder.availabilitySortOrderId;

	let patientAge =
		patientDetails && patientDetails.dateOfBirth ? moment().diff(patientDetails.dateOfBirth, 'years') : null;

	let patientZipCode = patientDetails && patientDetails.zipCode ? patientDetails.zipCode : null;
	let effectiveZipCode =
		decisionSupportSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.zipCode] ||
		careOrderSearchCriteria[AVAILABILITY_SEARCH_CRITERIA.zipCode] ||
		patientZipCode;

	let hasCalendarCriteria = combinedCalendarLookupCriteria?.length > 0;

	return {
		type: actionTypes.SET_INITIAL_AVAILABILITY_SEARCH_STATE,
		availability: {
			availabilitySearchCriteria: {
				returnAllCalendars: !hasCalendarCriteria,
				calendarIds: inputLists.calendarIds,
				minStartDate,
				maxStartDate,
				minStartTime,
				maxStartTime,
				returnAllDays: true,
				daysOfWeek: null,
				returnAllAppointmentTypes: false,
				appointmentTypeIds,
				appointmentTypeNames: [],
				appointmentModalityIds: [],
				returnAllAppointmentModalities: true,
				maxTimeSlotsPerDay: null,
			},
			calendarSearchCriteria: {
				identityCriteria: {
					calendarsByIdentities: combinedCalendarLookupCriteria,
					//TODO servicesByIdentities
					//TODO sitesByIdentities
					applyCalendarIdentityFilter: hasCalendarCriteria,
					ignoreInvalidCalendars: true,
					applyCriteriaToPreferredCalendars: true, // TODO shouldn't this be preserved from existing (config based) value - using the spread operator?
					// applyFiltersToPreferredCalendars, applyOtherCriteriaToPreferredCalendars, ignoreFitlersOnPreferredCalendar, preferredCalendarsIgnoreFilters
					practiceSystemIdentities: null,
				},
				providerCriteria: {
					serviceProviderTypeId,
					specialtyId,
				},
				patientCriteria: {
					patientAge,
				},
				distanceFilter: {
					zipCode: effectiveZipCode,
					maxDistanceInMiles: availabilitySearchConfig.defaultSearchRadius,
				},
				responseConfig: {
					sortOrder,
					includeProviderLanguages: true,
					serviceIdentityType: 'npi',
					siteIdentityType: 'site id',
					maxNumberOfCalendars: 201, //TODO: this should be a product instance setting
				},
			},
		},
	};
}

const mapLookupCriteria = (list, criteriaType, lookupKey, isSelected) => {
	let criteria = [];

	if (list?.length > 0) {
		criteria = list.map((x) => {
			return { [criteriaType]: { id: x, type: lookupKey }, isPreferredCalendar: isSelected };
		});
	}

	return criteria;
};

const combineSiteAndServiceIdentityCriteria = (siteCriteriaLookup, serviceCriteriaLookup, isPreferred) => {
	let siteCriteria = formatLookupCriteria(siteCriteriaLookup, 'siteIdentifier');
	let serviceCriteria = formatLookupCriteria(serviceCriteriaLookup, 'serviceIdentifier');

	if (siteCriteria.hasCriteria || serviceCriteria.hasCriteria) {
		return [
			{
				siteIdentifier: siteCriteria.hasCriteria
					? { type: siteCriteria.identifierType, id: siteCriteria.identityValues }
					: null,
				serviceIdentifier: serviceCriteria.hasCriteria
					? { type: serviceCriteria.identifierType, id: serviceCriteria.identityValues }
					: null,
				isPreferredCalendar: isPreferred,
			},
		];
	} else {
		return [];
	}
};

const formatLookupCriteria = (lookupCriteria, criteriaType) => {
	const hasCriteria = lookupCriteria?.length > 0;
	return {
		hasCriteria,
		identifierType: hasCriteria ? lookupCriteria[0][criteriaType].type : null,
		identityValues: hasCriteria ? lookupCriteria.map((x) => x[criteriaType].id).join(',') : null,
	};
};
