import * as actionTypes from './actionTypes';

export function storeTokenLinkParameters(inputValueType, inputName, inputValue) {
	return {
		type: actionTypes.STORE_TOKEN_LINK_PARAMETERS,
		inputValueType: inputValueType,
		inputName: inputName,
		inputValue: inputValue,
	};
}

export function clearValidEntryPoint() {
	return {
		type: actionTypes.CLEAR_ENTRY_POINT,
	};
}

export function setValidEntryPointToTrue() {
	return function (dispatch) {
		dispatch(setValidEntryPointToTrueAction());
		return Promise.resolve();
	};
}

function setValidEntryPointToTrueAction() {
	return {
		type: actionTypes.SET_ENTRY_POINT,
	};
}

export function fetchDecisionSupportSessionOutput(decisionSupportSessionId) {
	return {
		type: actionTypes.GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/decisionsupportsession/${decisionSupportSessionId}`
			}
		}
	};
}